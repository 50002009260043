import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import './App.css';

let apiUrl = process.env.REACT_APP_API_URL;
const loadingGifUrl = '/img/spinner_tutor_01.gif'; // 로딩 이미지 URL
const socket = io(apiUrl); // WebSocket 연결 설정

function App() {
  const [process1Result, setProcess1Result] = useState(null);
  const [process2Result, setProcess2Result] = useState(null);
  const [process3Result, setProcess3Result] = useState(null);
  const [targetResult, setTargetResult] = useState(null);
  const [process4Result, setProcess4Result] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const [basedImage, setBasedImage] = useState(null); // 선택된 이미지 상태 추가
  
  const [loading, setLoading] = useState(false); // 로딩 상태 추가
  const [progress, setProgress] = useState([]);

  const [loadingProcess4, setLoadingProcess4] = useState(false);
  const [errorProcess4, setErrorProcess4] = useState(null);

  useEffect(() => {
    socket.on('progress', (data) => {
      setProgress((prevProgress) => [...prevProgress, data]);
    });

    return () => {
      socket.off('progress');
    };
  }, []);

  useEffect(() => {
    if (targetResult) {
      console.log("target_result", targetResult);
    }
  }, [targetResult]); // targetResult가 변경될 때마다 실행


  // const handleProcess = async (processName, item = null) => {
  //   if (processName === 'google_vision_api') {
  //     setLoading(true); // Process 3 시작 시 로딩 상태로 설정
  //     setProgress([]); // 진행 상황 초기화
  //   }
  //   try {
  //     const response = await fetch(`${apiUrl}api/${processName}`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(item),
  //     });
  //     const data = await response.json();
  //     console.log(`Process ${processName} result:`, data);

  //     switch (processName) {
  //       case 'extract_items':
  //         setProcess1Result(data);
  //         break;
  //       case 'naver_shop_api':
  //         setProcess2Result(data);
  //         break;
  //       case 'google_vision_api':
  //         console.log("basedImage", basedImage);
  //         // setProcess3Result(data.sorted_image_urls);
  //         setProcess3Result(data.sorted_image_urls || []); // 배열로 설정
  //         setTargetResult(data.target_result.matching_item);
  //         console.log("target_result", targetResult);
  //         setLoading(false); // Process 3 완료 후 로딩 상태 해제
  //         break;
  //       case 'llm_crawling_lps':
  //         setProcess4Result(data);
  //         break;
  //       default:
  //         break;
  //     }
  //   } catch (error) {
  //     console.error(`Error with process ${processName}:`, error);
  //       if (processName === 'google_vision_api') {
  //         setLoading(false); // 오류 발생 시 로딩 상태 해제
  //       }
  //   }
  // };


  const handleProcess = async (processName, item = null) => {
    if (processName === 'google_vision_api') {
      setLoading(true); // Process 3 시작 시 로딩 상태로 설정
      setProgress([]); // 진행 상황 초기화
    }
    if (processName === 'llm_crawling_lps') {
      setLoadingProcess4(true); // Process 4 시작 시 로딩 상태로 설정
      setErrorProcess4(null); // Process 4 에러 상태 초기화
    }
    try {
      const response = await fetch(`${apiUrl}api/${processName}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(item),
      });
      const data = await response.json();
      console.log(`Process ${processName} result:`, typeof data);
      console.log(`Process ${processName} result:`, data);

      // llm_crawling_lps의 경우 데이터가 문자열로 올 경우 JSON 파싱 시도
      if (processName === 'llm_crawling_lps' && typeof data === 'string') {
        try {
            data = JSON.parse(data);
        } catch (parseError) {
            console.error('Failed to parse JSON:', parseError);
            setErrorProcess4('Invalid JSON format received');
            setLoadingProcess4(false);
            return;
        }
    }

      switch (processName) {
        case 'extract_items':
          setProcess1Result(data);
          break;
        case 'naver_shop_api':
          setProcess2Result(data);
          break;
        case 'google_vision_api':
          setProcess3Result(data.sorted_image_urls || []); // 배열로 설정
          setTargetResult(data.target_result.matching_item);
          setLoading(false); // Process 3 완료 후 로딩 상태 해제
          break;
        case 'llm_crawling_lps':
          // setErrorProcess4()
          setProcess4Result(data);
          setLoadingProcess4(false); // Process 4 완료 후 로딩 상태 해제
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(`Error with process ${processName}:`, error);
      if (processName === 'google_vision_api') {
        setLoading(false); // 오류 발생 시 로딩 상태 해제
      }
      if (processName === 'llm_crawling_lps') {
        setLoadingProcess4(false); // 오류 발생 시 로딩 상태 해제
        setErrorProcess4('크롤링 코드 개선 필요 웹페이지');
      }
    }
  };

  return (
    <div className="App">
      {/* <div style={{fontSize='22px', marginTop='15px',fontWeight='600'}}>AI 최저가 검색 솔루션</div> */}
        <h1>AI 최저가 검색 프로세스</h1>

      <div className="App-columns">
        {/* 통플 데이터 로드 */}
        <div className="App-column">
          <h2>Process 1</h2>
          <h3>상품 정보 불러오기</h3>
          <button onClick={() => handleProcess('extract_items')}>Run Process 1</button>
          {process1Result && (
            <div className="item-cards">
              {process1Result.items.map((item, index) => (
                <div
                  className={`item-card ${selectedItem === item ? 'selected' : ''}`}
                  key={index}
                  onClick={() => {
                    setSelectedItem(item);
                    setBasedImage(item.IMG_URI); // 선택된 이미지 상태 설정
                  }}
                >
                  <img src={item.IMG_URI} alt={item.ITEM_NAME} />
                  <div className="item-details">
                    {/* <h4>{item.ITEM_NAME}</h4> */}
                    <p><strong>Item:</strong> {item.ITEM_NAME}</p>
                    <p><strong>Model:</strong> {item.ITEM_MODEL}</p>
                    {/* <p><strong>Maker:</strong> {item.MAKER_NAME}</p> */}
                    {/* <p><strong>Specification:</strong> {item.ITEM_SPEC}</p> */}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>


        {/* 네이버 API 검색 */}
        <div className="App-column">
        <h2>Process 2</h2>
        <h3>네이버 API 검색</h3>
        <button onClick={() => handleProcess('naver_shop_api', selectedItem)}>Run Process 2</button>
        {process2Result && (
          <div className="item-cards">
            {process2Result.map((itemWrapper) => (
              <div className="item-card" key={itemWrapper.index}>
                <img src={itemWrapper.item.image} alt={itemWrapper.item.title} />
                <div className="item-details">
                  <h4 dangerouslySetInnerHTML={{ __html: itemWrapper.item.title }} />
                  <p><strong>Price:</strong> {itemWrapper.item.lprice}</p>
                  <p><strong>Mall:</strong> {itemWrapper.item.mallName}</p>
                  <p><strong>Link:</strong> <a href={itemWrapper.item.link} target="_blank" rel="noopener noreferrer">Product Link</a></p>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
        
        <div className="App-column">
          <h2>Process 3</h2>
          <h3>이미지 유사도 검증</h3>
          <button onClick={() => handleProcess('google_vision_api', { process2Result, basedImage })}>Run Process 3</button>
          {loading && (
            <div className="loading">
              <img src={loadingGifUrl} alt="Loading..." />
            </div>
          )}
          {progress.length > 0 && (
            <div className="progress-list">
              {progress.map((item, index) => (
                <div key={index} className="progress-item">
                  <p>Image URL: {item.url}</p>
                  <p>Similarity: {item.similarity.toFixed(2)}</p>
                </div>
              ))}
            </div>
          )}
          {process3Result && !loading && (
            <div className="similarity-results">
              {process3Result.map(([imageUrl, similarity], index) => (
                <div className="similarity-item" key={index}>
                  <img src={imageUrl} alt={`Similarity ${similarity}`} />
                  <div className="similarity-details">
                    <p><strong>Rank:</strong> {index+1}</p>
                    <p><strong>Similarity:</strong> {similarity.toFixed(2)}</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>


        {/* 크롤링 및 최저가 확인 */}
        <div className="App-column">
        <h2>Process 4</h2>
        <h3>크롤링 및 최저가 확인</h3>
        <button onClick={() => handleProcess('llm_crawling_lps', targetResult)}>Run Process 4</button>
        {loadingProcess4 && (
          <div className="loading">
            <img src={loadingGifUrl} alt="Loading..." />
          </div>
        )}
        {errorProcess4 && (
          <div className="error">
            <h4>상품명: {targetResult.title}</h4>
            <p>최저가: {targetResult.lprice}원</p>
            <p>link: <a href={targetResult.link}>link</a></p>
            <p>{errorProcess4}</p>
          </div>
        )}
        {process4Result && (
          <div className="lowest-price-results">
            <h4>상품명: {targetResult.title}</h4>
            <p>최저가: {targetResult.lprice}원</p>
            <p>link: <a href={targetResult.link}>link</a></p>
            {/* <ul className="lowest-price-results-list">
              {process4Result.업체별최저가.map((item, index) => (
                <li key={index} className="price-item">
                  <p><strong>판매처:</strong> {item.판매처}</p>
                  <p><strong>최저가:</strong> {item.최저가}</p>
                  <p><strong>배송비여부:</strong> {item.배송비여부}</p>
                  <p><strong>사러가기:</strong> <a href={item.사러가기url} target="_blank" rel="noopener noreferrer">링크</a></p>
                </li>
              ))}
            </ul> */}

            <ul className="lowest-price-results-list">
              {process4Result && process4Result.업체별최저가 ? (
                process4Result.업체별최저가.map((item, index) => (
                  <li key={index} className="price-item">
                    <p><strong>판매처:</strong> {item.판매처}</p>
                    <p><strong>최저가:</strong> {item.최저가}</p>
                    <p><strong>배송비여부:</strong> {item.배송비여부}</p>
                    <p><strong>사러가기:</strong> <a href={item.사러가기url} target="_blank" rel="noopener noreferrer">링크</a></p>
                  </li>
                ))
              ) : (
                <p>업체별 최저가 정보를 불러올 수 없습니다.</p>
              )}
            </ul>

          </div>
        )}
      </div>



      </div>
    </div>
  );
}

export default App;
